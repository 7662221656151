import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MenuBar from '../home/MenuBar';
import Footer from '../home/v3/Footer';
import CreditCard2 from './CreditCard2';
import {
  colors,
  ErrorText,
  Content,
  Container,
  Button as ButtonV2,
  mobileThresholdPixels,
} from '../home/v2/styledComponents';
import {
  isCardTypeValid,
  isCardNumberValid,
  errorMessages,
} from '../../services/mangopay';

import mangoPayLogo from '../../assets/Mangopay2.png';
import axaLogo from '../../assets/axa.png';
import acceptedCBs from '../../assets/cb.png';

const TextTitle = styled.p`
  font-family: Roboto;
  font-size: 30px;
  line-height: 42px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 22px;
    line-height: 31px;
  }
`;

const TextTitleContainer = styled.p`
  font-family: Roboto;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  margin: 31px 10px 31px 0px;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    text-align: center;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || 'center'};
  ${props => props.marginLeft && 'margin-left: 41px;'}
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${props => props.marginBottom || '30'}px;
    margin-left: 0px;
    ${props => !props.row && 'flex-direction: column;'}
    ${props => props.marginsMobile && 'margin: 0px 18px;'}
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  padding-left: 46px;
  margin-top: 28px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    margin: 28px 0 0 0;
    padding: 0px 40px;
  }
`;

const TextPaymentContainerFooter = styled.p`
  font-family: Roboto;
  font-size: 18px;
  text-align: left;
  margin-bottom: 15px;
  margin-top: 30px;
  color: ${colors.navy};
  opacity: 0.3;
  margin-right: 15px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const TextGuarantees = styled.p`
  font-family: Roboto;
  font-size: 15px;
  color: ${colors.darkGrey};
  ${props => props.italics && 'font-style: italic; font-weight: 300;'}
`;

const StyledImg = styled.img`
  width: 47px;
  height: 47px;
  margin-right: 15px;
`;

const ImgAllowedCBs = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 136px;
  }
`;

const Button = styled(ButtonV2)`
  margin: 30px 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 320px;
    max-width: 80vw;
    align-self: center;
  }
`;

class PaymentPageBrand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardName: '',
      cardNumber: '',
      cvv: '',
    };
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.validateCardNumber = this.validateCardNumber.bind(this);
  }

  validateCardNumber(cardNumber) {
    const strippedCardNumber = cardNumber.replace(/ /g, '');
    if (!isCardTypeValid(strippedCardNumber)) {
      this.setState({
        cardError: 'les cartes American Express (Amex) ne sont pas supportées',
      });
    } else if (
      strippedCardNumber &&
      strippedCardNumber.length > 15 &&
      !isCardNumberValid(strippedCardNumber)
    ) {
      this.setState({
        cardError: 'Oups une erreur s\'est glissé. Êtes-vous sûr du numéro ?',
      });
    } else {
      this.setState({ cardError: null });
    }
  }

  inputChange(field, value) {
    if (field === 'cardNumber') this.validateCardNumber(value);
    this.setState({ [field]: value });
  }

  isValid() {
    const { expiryMonth, expiryYear, cvv, cardNumber } = this.state;
    return (
      expiryMonth &&
      expiryYear &&
      cvv &&
      cardNumber &&
      isCardTypeValid(cardNumber) &&
      isCardNumberValid(cardNumber)
    );
  }

  submit() {
    if (!this.isValid()) {
      return this.setState({ formError: true });
    }
    const { expiryMonth, expiryYear, cvv, cardNumber, cardId } = this.state;
    const { submit, brandId } = this.props;
    const expiryDate = `${expiryMonth}${expiryYear}`;
    const card = { expiryDate, cvv, cardNumber, cardId };
    return submit(card, brandId);
  }

  renderError() {
    const { error, errorType } = this.props;
    const message =
      (errorType && errorMessages[errorType]) || 'Une erreur a eu lieu';
    return (
      <div>
        {error && <ErrorText>{message}</ErrorText>}
        {this.state.formError && (
          <ErrorText>Merci de remplir tous les champs</ErrorText>
        )}
      </div>
    );
  }

  render() {
    const { cardName, cardNumber, cvv, formError, cardError } = this.state;
    const { isLoading } = this.props;
    return (
      <Container>
        <MenuBar />
        <Content>
          <TextTitle>
            Enregistrez une carte
          </TextTitle>
          <StyledContainer marginBottom="0">
            <SubContainer>
              <StyledContainer alignItems="center" row marginBottom="0">
                <TextTitleContainer>
                  Règlement 100% sécurisé via MangoPay
                </TextTitleContainer>
                <img src={mangoPayLogo} alt="Logo MangoPay" />
              </StyledContainer>
              <CreditCard2
                onChange={this.inputChange}
                formError={formError}
                cardName={cardName}
                cardNumber={cardNumber}
                cvv={cvv}
                error={cardError}
                isBrand
              />
              <StyledContainer alignItems="center" justifyContent="right" row marginBottom="47">
                <TextPaymentContainerFooter>Nous acceptons : </TextPaymentContainerFooter>
                <ImgAllowedCBs src={acceptedCBs} alt="Moyens de paiement acceptés" />
              </StyledContainer>
            </SubContainer>
          </StyledContainer>
          <StyledContainer>
            {isLoading ?
              <Button navy>{'chargement…'}</Button> :
              <Button navy onClick={this.submit}>Valider</Button>
            }
          </StyledContainer>
        </Content>
        <StyledContainer marginLeft justifyContent="left" row marginsMobile alignItems="center">
          <StyledImg src={axaLogo} alt="Logo Axa" />
          <TextGuarantees>Tous les vêtements sont garantis par notre partenaire AXA.</TextGuarantees>
        </StyledContainer>
        <StyledContainer marginLeft justifyContent="left" marginsMobile>
          <TextGuarantees italics>
            Conformément aux réglementations de la CNIL destinées à renforcer
            la protection des données personnelles des consommateurs sur les sites marchands,
            vos données sont cryptées et sécurisées
          </TextGuarantees>
        </StyledContainer>
        <Footer />
      </Container>
    );
  }
}

PaymentPageBrand.propTypes = {
  submit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  errorType: PropTypes.string,
  brandId: PropTypes.string,
};

PaymentPageBrand.defaultProps = {
  isLoading: false,
  error: false,
  errorType: null,
  brandId: null,
};

export default PaymentPageBrand;
