import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';

import routesMap from '../../../Routes';
import PaymentPageBrand from '../../../components/order/PaymentPageBrand';
import callApi from '../../../services/api';
import { registerBrandCard } from '../../../services/mangopay';
import Layout from '../../../layouts/index';


class PaymentContainerBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submitCard = this.submitCard.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    if (window.location.hostname !== 'localhost') {
      window.onbeforeunload = function () {
        return true;
      };
    }
  }

  redirect3DS(redirectUrl, total) {
    if (redirectUrl) {
      this.setState({ redirecting: true });
      window.onbeforeunload = null;
      window.location = redirectUrl;
    } else {
      this.redirectToSuccess(total);
    }
  }

  redirectToSuccess() { // eslint-disable-line
    window.onbeforeunload = null;
    navigate(routesMap.SuccessPaymentBrand.url);
  }

  submitCard(cardData, brandId) {
    this.setState({ error: false, isLoading: true, errorType: undefined });
    return registerBrandCard(cardData, brandId)
      .then((card) => {
        callApi(`brands/${brandId}/payment`, 'post', { cardToken: card.CardId });
      })
      .then(() => {
        this.redirectToSuccess();
      })
      .catch((response) => {
        this.setState({ error: true, isLoading: false, errorType: response.error });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  render() {
    const { error, errorType, isLoading } = this.state;
    if (typeof location === 'undefined') return null;
    return (
      <Layout>
        <Router
          location={location}
          key={location.key}
        >
          <PaymentPageBrand
            path="/enregistrer-un-moyen-de-paiement/:brandId"
            error={error}
            errorType={errorType}
            isLoading={isLoading}
            submit={this.submitCard}
          />
        </Router>
      </Layout>
    );
  }
}

PaymentContainerBrand.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      order: PropTypes.shape({}),
    }),
  }),
};

PaymentContainerBrand.defaultProps = {
  location: {},
};

export default PaymentContainerBrand;
