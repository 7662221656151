import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import CardReactFormContainer from 'card-react';
import ReactTooltip from 'react-tooltip';
import { Form1, ErrorText } from '../styledComponents';
import {
  FormInput, colors, mobileThresholdPixels,
} from '../home/v2/styledComponents';

import helpIcon from '../../assets/boutonAide.jpg';
import cvvImg from '../../assets/CVV.png';

const months = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

const years = () => {
  const date = new Date();
  const yearNow = date.getFullYear();
  const arrYears = [];
  for (let i = 0; i < 10; i += 1) {
    const year = (yearNow + i).toString();
    arrYears.push({ value: year, label: year });
  }
  return arrYears;
};

const customStyles = {
  control: base => ({
    ...base,
    borderColor: colors.navy,
    height: '40px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: '300',
    borderRadius: '0',
    paddingLeft: '7px',
  }),
};

const StyledFormInput = styled(FormInput)`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin: 0;
  padding: 0, 0, 0, 15;
  width: ${props => props.small ? '92px' : '85%'};
  ::placeholder {
    color: ${colors.navy};
    opacity: 0.3;
  }
  @media (max-width: ${mobileThresholdPixels}) {
    ${props => !props.small && 'width: 94%;'}
    margin-bottom: 30px;
  }
`;

const FieldLabel = styled.p`
  font-family: Roboto;
  font-size: 18px;
  text-align: left;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 30px;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
  }
`;

const ExpiryDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 30px;
  }
`;

const StyledSelect = styled(Select)`
  width: 107px;
  margin-right: 40px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`;

const StyledImg = styled.img`
  margin-left: 15px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 30px;
  }
`;

const TooltipText = styled.p`
  font-family: Roboto;
  font-weight: 300;
  font-size: 15px;
  color: ${colors.navy};
  width: 274px;
`;

const TooltipDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  opacity: 1 !important;
  box-shadow: 0px 10px 10px rgba(190, 190, 190, 0.5) !important;
`;

const DivContainer = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 18px;
  }
`;

class CreditCard2 extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedMonth: '',
      selectedYear: '',
    };
    this.handleExpiryDate = this.handleExpiryDate.bind(this);
  }

  handleExpiryDate(selectedValue, stateName, propName) {
    const { onChange } = this.props;
    onChange(propName, selectedValue.value);
    this.setState({ [stateName]: selectedValue });
  }

  render() {
    const { onChange, formError, cardName, cardNumber, cvv, error, isBrand } = this.props;
    const { selectedMonth, selectedYear } = this.state;
    return (
      <DivContainer>
        <CardReactFormContainer
          container="card-wrapper-2"
          formInputsNames={{
            number: 'cardNumber',
            expiry: 'CCexpiry',
            cvc: 'CCcvc',
            name: 'cardName',
          }}
          initialValues={{
            number: cardNumber,
            cvc: cvv,
            name: cardName,
          }}
          classes={{
            valid: 'valid-input', // optional — default 'jp-card-valid'
            invalid: 'invalid-input', // optional — default 'jp-card-invalid'
          }}
          formatting
        >
          <div id="card-wrapper-2" />
          <StyledReactTooltip place="top" type="light" effect="float" id="cvvTooltip">
            <TooltipText>
              Un cryptogramme visuel de carte est une série unique de trois ou quatre chiffres,
              imprimée sur la plupart des cartes de crédit.
            </TooltipText>
            <TooltipDiv>
              <img src={cvvImg} alt="Où trouver le CVV ?" />
            </TooltipDiv>
          </StyledReactTooltip>
          <Form1 marginTop="0px">
            <FieldLabel>{isBrand ? 'Titulaire de la carte' : 'Nom du titulaire de la carte'}</FieldLabel>
            <StyledFormInput
              name={'cardName'}
              placeholder={isBrand ? 'Titulaire de la carte' : 'Prénom Nom'}
              onChange={e => onChange('cardName', e.target.value)}
            />
            <FieldLabel>Numéro de la carte</FieldLabel>
            <StyledFormInput
              type="text" name="cardNumber"
              placeholder="XXXX XXXX XXXX XXXX"
              onChange={e => onChange('cardNumber', e.target.value)}
              error={formError && !cardNumber}
            />
            {error && <ErrorText>{error}</ErrorText>}
            <FieldLabel>{"Date d'expiration"}</FieldLabel>
            <ExpiryDateContainer>
              <StyledSelect
                placeholder="MM"
                value={selectedMonth}
                onChange={selectedVal => this.handleExpiryDate(selectedVal, 'selectedMonth', 'expiryMonth')}
                options={months}
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: '#bec4c9',
                  },
                })}
              />
              <StyledSelect
                placeholder="AAAA"
                value={selectedYear}
                onChange={selectedVal => this.handleExpiryDate(selectedVal, 'selectedYear', 'expiryYear')}
                options={years()}
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: '#bec4c9',
                  },
                })}
              />
            </ExpiryDateContainer>
            <input placeholder="MM/YY" type="text" name="CCexpiry" style={{ display: 'none' }} />
            {/* we don't display the input container above because it is not the right format */}
            <FieldLabel>CVV</FieldLabel>
            <StyledContainer>
              <StyledFormInput
                small
                name={'CCcvc'}
                placeholder={'XXX'}
                onChange={e => onChange('cvv', e.target.value)}
              />
              <StyledImg src={helpIcon} alt="C'est quoi le CVV ?" data-tip data-for="cvvTooltip" />
            </StyledContainer>
          </Form1>
        </CardReactFormContainer>
      </DivContainer>
    );
  }
}

CreditCard2.propTypes = {
  onChange: PropTypes.func.isRequired,
  formError: PropTypes.bool,
  cardName: PropTypes.string,
  cardNumber: PropTypes.string,
  cvv: PropTypes.string,
  error: PropTypes.string,
  isBrand: PropTypes.bool,
};

CreditCard2.defaultProps = {
  formError: false,
  cardName: '',
  cardNumber: '',
  cvv: '',
  error: '',
  isBrand: false,
};

export default CreditCard2;
