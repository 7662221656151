import mangoPay from 'mangopay-cardregistration-js-kit';
import creditCardValidator from 'credit-card-validator';
import callApi from './api';

export function formatExpiryDate(expiryDate) {
  const trimedExpiryDate = expiryDate.replace(/ /g, '').replace(/\//g, '');
  if (trimedExpiryDate.length === 6) {
    // removed 20 of the year, eg 082017 => 0817
    return trimedExpiryDate.slice(0, 2) + trimedExpiryDate.slice(4, 6);
  }
  return trimedExpiryDate;
}

export function registerCard(cardData, customerId) {
  const { cardNumber, cvv, cvc, expiryDate } = cardData;
  const cvx = cvv || cvc;
  return new Promise((resolve, reject) =>
    callApi(customerId ? `public/initCardRegistration/${customerId}` : 'public/initCardRegistration')
      .then(({ cardRegistration, mangopayBaseURL, mangopayClientId }) => {
        mangoPay.cardRegistration.baseURL = mangopayBaseURL;
        mangoPay.cardRegistration.clientId = mangopayClientId;

        mangoPay.cardRegistration.init({
          cardRegistrationURL: cardRegistration.CardRegistrationURL,
          preregistrationData: cardRegistration.PreregistrationData,
          accessKey: cardRegistration.AccessKey,
          Id: cardRegistration.Id,
        });

        const cardDataRequest = {
          cardNumber: cardNumber.replace(/ /g, ''),
          cardExpirationDate: formatExpiryDate(expiryDate),
          cardCvx: cvx.replace(/ /g, ''),
          cardType: cardRegistration.CardType.replace(/ /g, ''),
        };

        mangoPay.cardRegistration.registerCard(
          cardDataRequest,
          (res) => { resolve(res); },
          (res) => {
            const error = res;
            error.cardNumber = cardDataRequest.cardNumber && cardDataRequest.cardNumber.replace(/\d/g, 'd');
            error.cvx = cardDataRequest.cardCvx && cardDataRequest.cardCvx.replace(/\d/g, 'd');
            error.expirationDate = cardDataRequest.cardExpirationDate;
            error.cardType = cardDataRequest.cardType;
            if (typeof Raven !== 'undefined') {
              Raven.captureException(JSON.stringify(error)); // eslint-disable-line
            }

            reject({
              error: res.ResultMessage,
              errorCode: res.ResultCode,
            });
          },
        );
      }),
  );
}

export function registerBrandCard(cardData, brandId) {
  const { cardNumber, cvv, cvc, expiryDate } = cardData;
  const cvx = cvv || cvc;
  return new Promise((resolve, reject) =>
    callApi(`public/brands/initCardRegistration/${brandId}`)
      .then((res) => {
        const { cardRegistration, mangopayBaseURL, mangopayClientId } = res;

        mangoPay.cardRegistration.baseURL = mangopayBaseURL;
        mangoPay.cardRegistration.clientId = mangopayClientId;

        mangoPay.cardRegistration.init({
          cardRegistrationURL: cardRegistration.CardRegistrationURL,
          preregistrationData: cardRegistration.PreregistrationData,
          accessKey: cardRegistration.AccessKey,
          Id: cardRegistration.Id,
        });


        const cardDataRequest = {
          cardNumber: cardNumber.replace(/ /g, ''),
          cardExpirationDate: formatExpiryDate(expiryDate),
          cardCvx: cvx.replace(/ /g, ''),
          cardType: cardRegistration.CardType.replace(/ /g, ''),
        };

        mangoPay.cardRegistration.registerCard(
          cardDataRequest,
          (resRegistration) => { resolve(resRegistration); },
          (resRegistration) => {
            const error = resRegistration;
            error.cardNumber = cardDataRequest.cardNumber && cardDataRequest.cardNumber.replace(/\d/g, 'd');
            error.cvx = cardDataRequest.cardCvx && cardDataRequest.cardCvx.replace(/\d/g, 'd');
            error.expirationDate = cardDataRequest.cardExpirationDate;
            error.cardType = cardDataRequest.cardType;
            if (typeof Raven !== 'undefined') {
              Raven.captureException(JSON.stringify(error)); // eslint-disable-line
            }

            reject({
              error: resRegistration.ResultMessage,
              errorCode: resRegistration.ResultCode,
            });
          },
        );
      })
      .catch(reject),
  );
}

export function isCardNumberValid(cardNumber = '') {
  return creditCardValidator.validateCard(cardNumber);
}

export function isCardTypeValid(cardNumber = '') {
  return creditCardValidator.getCardName(cardNumber) !== 'americanexpress';
}

export const errorMessages = {
  emailUnique: 'Erreur : cet email a déjà été utilisé',
  CVV_FORMAT_ERROR: 'Le code CVV doit être sur 3 ou 4 chiffres',
  CARD_NUMBER_FORMAT_ERROR: 'Numéro de carte erroné',
  EXPIRY_DATE_FORMAT_ERROR: 'Date d‘expiration de la carte non reconnu',
  PAST_EXPIRY_DATE_ERROR: 'Date d‘expiration de la carte dépassée',
  INACTIVE_CARD: `La carte bancaire n‘a pas pu être utilisée par notre prestataire bancaire.
    Merci d‘en utiliser une autre ou d'ajouter à nouveau cette carte.`,
  PAYMENT_ERROR: 'Une erreur de paiement a eu lieu',
};
